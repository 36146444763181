import React from "react";
import Logo from "../../../assets/images/logo.svg";
import "../../../style/components/Header.css";
import "../../../style/pages/auth.css";
import { logoutuser } from "../../../services/auth";
import { useMsal } from "@azure/msal-react";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LogoHeaderProps { }
async function logout(values: any) {
  const data = await logoutuser();
  if (data) {
    localStorage.clear();
    localStorage.removeItem("userDetails");
    window.location.reload();
  }
}

export const LogoHeader: React.FC<LogoHeaderProps> = () => {
  const { instance } = useMsal();
  const handleLogout = (logoutType: string) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/auth/login",
        mainWindowRedirectUri: "/auth/login", // redirects the top level app after logout
      });
    }
  };
  return (
    <>
      <div className="secondary-header">
        <img tabIndex={0} src={Logo} alt="msi logo" aria-label="MSI logo" />
        <button onClick={() => handleLogout("popup")}>
          <a >Logout</a>
        </button>
        <h1 className="sr-only">Logo</h1>
      </div>
    </>
  );
};
