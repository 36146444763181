import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    IQuestionAnswerAPI,
    questionTypes,
} from "../components/questionnaires/questionnaire-builder/QuestionnarieBuilderOptions";
import {
    questionnariePatientAnswer,
    questionnarieUpdatePatientAnswerByAdmin,
} from "../services/api/QuestionnarieApi";
import useAuthContext from "../context/provider/AuthContext";
import { UserTypes } from "../models/UserTypes";

export const phoneEligibilitySlice = createSlice({
    name: "phone_eligibility",
    initialState: {},
    reducers: {
        setPHONEligiblity: (state: any, action: PayloadAction<object>) => {
            state.data = action.payload;
            const answer: any = [];
            state?.data?.QuestionSetIds?.questions.map((question: any) => {
                if (
                    question.type === questionTypes.multiplechoice ||
                    question.type === questionTypes.yesnoquestion ||
                    question.type === questionTypes.dropdown || question.type === questionTypes.radioinput
                ) {
                    question.userAnswer?.map((ans: any) => {
                        answer.push({
                            answer: ans?.answer,
                            optionId: ans?.optionId,
                            questionId: question.id,
                        });
                    });
                } else {
                    answer.push({
                        answer: question.userAnswer?.[0]?.answer,
                        optionId: question.userAnswer?.[0]?.optionId,
                        questionId: question.id,
                    });
                }
            });
            if (state.data.status !== "completed" && state.data.id) {
                const answerPayload: IQuestionAnswerAPI = {
                    shareId: state.data.id,
                    questionSetId: state.data.questionSetId,
                    status: "draft",
                    answers: answer,
                };
                if (state.data?.admin) {
                    questionnarieUpdatePatientAnswerByAdmin(answerPayload);
                } else {
                    questionnariePatientAnswer(answerPayload);
                }
            }
        },
        submitPHONEligiblity: (state: any, action: PayloadAction<object>) => {
            state.data = action.payload;
            const answer: any = [];
            state?.data?.QuestionSetIds?.questions.map((question: any) => {
                if (
                    question.type === questionTypes.multiplechoice ||
                    question.type === questionTypes.yesnoquestion ||
                    question.type === questionTypes.dropdown
                ) {
                    question.userAnswer.map((ans: any) => {
                        answer.push({
                            answer: ans?.answer,
                            optionId: ans?.optionId,
                            questionId: question.id,
                        });
                    });
                } else {
                    answer.push({
                        answer: question.userAnswer?.[0]?.answer,
                        optionId: question.userAnswer?.[0]?.optionId,
                        questionId: question.id,
                    });
                }
            });
            const answerPayload: IQuestionAnswerAPI = {
                shareId: state.data.id,
                questionSetId: state.data.questionSetId,
                status: state.data.status === "hold" ? "hold" : "completed",
                pendingCallbackStatus: state.data.pendingCallbackStatus,
                answers: answer,
            };

            if (state.data.admin) {
                questionnarieUpdatePatientAnswerByAdmin(answerPayload);
            } else {
                questionnariePatientAnswer(answerPayload);
            }
        },
    },
});

export const { setPHONEligiblity, submitPHONEligiblity } =
    phoneEligibilitySlice.actions;

export default phoneEligibilitySlice.reducer;
