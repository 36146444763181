import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  IQuestionAnswerAPI,
  questionTypes,
} from "../components/questionnaires/questionnaire-builder/QuestionnarieBuilderOptions";
import { questionnariePatientAnswer } from "../services/api/QuestionnarieApi";

interface AppointmentFormsState {
  data: null | object;
  status: string;
  error: string | null;
}

const initialState: AppointmentFormsState = {
  data: null,
  status: "idle",
  error: null,
};

enum QuestionSetSaveStatus {
  Draft = "draft",
  Hold = "hold",
  Completed = "completed",
}

// Define createAsyncThunk for setting Gestation forms data
export const setAppointmentGestationDataAsync = createAsyncThunk(
  "appointment_forms/setAppointmentGestationData",
  async (payload: any, thunkAPI) => {
    try {
      const answersPayload = await prepareAnswerPayload(
        payload,
        QuestionSetSaveStatus.Draft
      );
      await questionnariePatientAnswer(answersPayload);
      return payload;
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Failed to set static forms data";
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

// Define createAsyncThunk for setting static forms data
export const setAppointmentFormsDataAsync = createAsyncThunk(
  "appointment_forms/setAppointmentFormsData",
  async (payload: any, thunkAPI) => {
    try {
      if (payload?.status !== "completed" && payload?.id) {
        const answersPayload = await prepareAnswerPayload(
          payload,
          QuestionSetSaveStatus.Draft
        );
        await questionnariePatientAnswer(answersPayload);
      }
      return payload;
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Failed to set static forms data";
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

// Define createAsyncThunk for setting static forms data
export const holdAppointmentFormsDataAsync = createAsyncThunk(
  "appointment_forms/holdAppointmentFormsData",
  async (payload: any, thunkAPI) => {
    try {
      if (payload?.status !== "completed" && payload?.id) {
        payload.status = QuestionSetSaveStatus.Hold;
        const answersPayload = await prepareAnswerPayload(
          payload,
          QuestionSetSaveStatus.Hold
        );
        await questionnariePatientAnswer(answersPayload);
      }
      return payload;
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Failed to hold static forms data";
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

// Define createAsyncThunk for submitting static forms data
export const submitAppointmentFormsDataAsync = createAsyncThunk(
  "appointment_forms/submitStaticFormsData",
  async (payload: any, thunkAPI) => {
    try {
      if (payload?.status !== "completed" && payload?.id) {
        const answersPayload = await prepareAnswerPayload(
          payload,
          QuestionSetSaveStatus.Completed
        );
        await questionnariePatientAnswer(answersPayload);
      }
      return payload;
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Failed to submit static forms data";
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const appointmentFormsSlice = createSlice({
  name: "appointment_forms",
  initialState,
  reducers: {
    // Any synchronous reducers can go here if needed
    setDataToNull: (state) => {
      state.data = null;
    },
    setAppointmentFormsData: (state, action: PayloadAction<object>) => {
      state.data = action.payload;
    },
    holdAppointmentFormsData: (state, action: PayloadAction<object>) => {
      state.data = action.payload;
    },
    submitStaticFormsData: (state, action: PayloadAction<object>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle pending, fulfilled, and rejected actions for setAppointmentFormsDataAsync
    builder.addCase(setAppointmentFormsDataAsync.pending, (state: any) => {
      state.status = "loading";
    });
    builder.addCase(
      setAppointmentFormsDataAsync.fulfilled,
      (state: any, action: PayloadAction<object>) => {
        // Use PayloadAction<object> here
        state.status = "succeeded";
        state.data = action.payload;
      }
    );
    builder.addCase(
      setAppointmentFormsDataAsync.rejected,
      (state: any, action: PayloadAction<object | any>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to set static forms data";
      }
    );

    // Handle pending, fulfilled, and rejected actions for submitAppointmentFormsDataAsync
    builder.addCase(holdAppointmentFormsDataAsync.pending, (state: any) => {
      state.status = "loading";
    });
    builder.addCase(
      holdAppointmentFormsDataAsync.fulfilled,
      (state: any, action: PayloadAction<object>) => {
        // Use PayloadAction<object> here
        state.status = "succeeded";
        state.data = action.payload;
      }
    );
    builder.addCase(
      holdAppointmentFormsDataAsync.rejected,
      (state: any, action: PayloadAction<object | any>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to set static forms data";
      }
    );

    // Handle pending, fulfilled, and rejected actions for submitAppointmentFormsDataAsync
    builder.addCase(submitAppointmentFormsDataAsync.pending, (state: any) => {
      state.status = "loading";
    });
    builder.addCase(
      submitAppointmentFormsDataAsync.fulfilled,
      (state: any, action: PayloadAction<object>) => {
        // Use PayloadAction<object> here
        state.status = "succeeded";
        state.data = action.payload;
      }
    );
    builder.addCase(
      submitAppointmentFormsDataAsync.rejected,
      (state: any, action: PayloadAction<object | any>) => {
        state.status = "failed";
        state.error = action.payload || "Failed to set static forms data";
      }
    );
  },
});

/**
 * Determines the status of a question set based on its preparation status and current status.
 * @param prepareStatus - The preparation status of the question set.
 * @param currentStatus - The current status of the question set.
 * @returns The determined status of the question set.
 */
const getQuestionSetStatus = (
  prepareStatus: QuestionSetSaveStatus,
  currentStatus: string
): string => {
  switch (true) {
    // If the current status is draft or empty and the preparation status is draft, set status to draft.
    case (currentStatus === QuestionSetSaveStatus.Draft ||
      currentStatus === "") &&
      prepareStatus === QuestionSetSaveStatus.Draft:
      return QuestionSetSaveStatus.Draft;

    // If the current status is hold and the preparation status is completed, set status to hold.
    case currentStatus === QuestionSetSaveStatus.Hold &&
      prepareStatus === QuestionSetSaveStatus.Hold:
      return QuestionSetSaveStatus.Hold;

    // If the current status is hold and the preparation status is completed, set status to hold.
    case currentStatus === QuestionSetSaveStatus.Hold &&
      prepareStatus === QuestionSetSaveStatus.Completed:
      return QuestionSetSaveStatus.Hold;

    // If the preparation status is completed and the current status is not hold, set status to completed.
    case prepareStatus === QuestionSetSaveStatus.Completed &&
      currentStatus !== QuestionSetSaveStatus.Hold:
      return QuestionSetSaveStatus.Completed;

    // Default case: If none of the specific conditions match, set status to draft.
    default:
      return QuestionSetSaveStatus.Draft;
  }
};

/**
 * Prepares the answer payload for submitting a question set based on user answers and preparation status.
 * @param userAnswer - The user's answers to the questions.
 * @param prepareStatus - The preparation status of the question set.
 * @returns The prepared answer payload for submission.
 */

const prepareAnswerPayload = async (
  userAnswer: any,
  prepareStatus: QuestionSetSaveStatus
) => {
  // Initialize an empty array to store answers
  const answer: any = [];
  // Process each question in the user's answers
  userAnswer?.QuestionSetIds?.questions.map((question: any) => {
    if (question.type === questionTypes.multiplechoice) {
      // Process multiple-choice questions
      question.userAnswer.map((ans: any) => {
        answer.push({
          answer: ans?.answer,
          optionId: ans?.optionId,
          questionId: question.id,
        });
      });
    } else if (question.type === questionTypes.radioinput) {
      answer.push({
        answer: null,
        optionId: question.userAnswer?.[0]?.optionId,
        questionId: question.id,
      });
    } else {
      answer.push({
        answer: question.userAnswer?.[0]?.answer,
        optionId: question.userAnswer?.[0]?.optionId,
        questionId: question.id,
      });
    }
  });
  // Determine the status based on preparation status and userAnswer status
  const status = await getQuestionSetStatus(prepareStatus, userAnswer.status);
  // Construct the answer payload for submission
  const filteredArray =
    prepareStatus === QuestionSetSaveStatus.Draft
      ? answer
      : answer.filter((item: any) => item.answer || item.optionId);
  // Construct the answer payload for submission
  const answerPayload: IQuestionAnswerAPI = {
    shareId: userAnswer.id,
    questionSetId: userAnswer.questionSetId,
    status: status,
    answers: filteredArray,
  };
  return answerPayload;
};

export const {
  setAppointmentFormsData,
  holdAppointmentFormsData,
  submitStaticFormsData,
} = appointmentFormsSlice.actions;

export default appointmentFormsSlice.reducer;
