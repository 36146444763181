import { lazy } from "react";

export default [
  {
    title: "Signup Page",
    component: lazy(() => import("./Signup")),
    url: "/auth/signup",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Signup Details",
    component: lazy(() => import("./Signup_details")),
    url: "/auth/signup_details/:service_code",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Signup Details",
    component: lazy(() => import("./Signup_details")),
    url: "/auth/signup_details",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },

  {
    title: "Signup Address",
    component: lazy(() => import("./Signup_address")),
    url: "/auth/signup_address",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Signup Mobile",
    component: lazy(() => import("./Signup_mobileNo")),
    url: "/auth/signup_mobile",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Signup Password",
    component: lazy(() => import("./Signup_password")),
    url: "/auth/signup_password",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Verify account",
    component: lazy(() => import("./VerifyAccount")),
    url: "/signup/verifyaccount",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Verification Code",
    component: lazy(() => import("./VerificationCode")),
    url: "/signup/verificationcode",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Complete Verification",
    component: lazy(() => import("./CompleteVerification")),
    url: "/signup/completeverification",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Complete Verification",
    component: lazy(() => import("./CompleteVerification")),
    url: "/signup/completeverification/:token",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Notification Preference",
    component: lazy(() => import("./NotificationPreference")),
    url: "/signup/notificationpreference",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Notification TurnON",
    component: lazy(() => import("./NotificationTurnON")),
    url: "/signup/notificationturnon",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Check Email",
    component: lazy(() => import("./CheckEmail")),
    url: "/signup/checkemail",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Security",
    component: lazy(() => import("./Security")),
    url: "/signup/security",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "SecurityPIN",
    component: lazy(() => import("./SecurityPIN")),
    url: "/signup/securitypin",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Contact Preference",
    component: lazy(() => import("./ContactPreference")),
    url: "/signup/contactpreference",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Memorable Preference",
    component: lazy(() => import("./MemorablePreference")),
    url: "/signup/memorablepreference",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Automated Messages",
    component: lazy(() => import("./AutomatedPreference")),
    url: "/signup/automatedmessages",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "Client"],
  },
];
