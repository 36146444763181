import React, { useEffect, useRef } from "react";
import "../style/components/Footer.css";
import Inactive from "../assets/images/Inactive.svg";

const InactiveAlert = () => {
  if (process.env.REACT_APP_DEV_MODE) {
    return <></>;
  }

  const [timer, settimer] = React.useState("");
  const [inactive, setinactive] = React.useState(false);
  const warning_sec = 120;
  const warning_timeout_sec = 480000;
  const timeout_sec = 600000;
  const timeout: any = useRef(null);
  const [countdown, setCountdown] = React.useState(warning_sec);
  function secondsToTime(secs: any) {
    const divisor_for_minutes = Number(secs) % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);
    const divisor_for_seconds = divisor_for_minutes % 60;
    const seconds = Math.ceil(divisor_for_seconds);
    const obj =
      minutes > 0
        ? minutes + " minutes " + seconds + " seconds"
        : seconds + " seconds";
    settimer(obj);
    return;
  }
  const resetTimer = () => {
    setCountdown(warning_sec);
    clearTimeout(timeout.current1);
    clearInterval(timeout.current2);
    clearTimeout(timeout.current3);
  };

  function clearsession() {
    localStorage.clear();
    localStorage.removeItem("userDetails");
    window.open("https://www.bbc.com", "_blank", "noopener,noreferrer");
    window.open("https://www.google.com", "_blank", "noopener,noreferrer");
    window.open("https://www.bing.com", "_blank", "noopener,noreferrer");
    window.open("about:blank", "_self");
    window.close();
  }

  const handleLogoutTimer = () => {
    setinactive(false);
    timeout.current1 = setTimeout(() => {
      setinactive(true);
      document.removeEventListener("mousemove", resetTimer);
      timeout.current2 = setInterval(() => {
        setCountdown((countdown) => countdown - 1);
      }, 1000);
    }, warning_timeout_sec);

    timeout.current3 = setTimeout(() => {
      clearsession();
    }, timeout_sec);
  };

  useEffect(() => {
    handleLogoutTimer();
    document.addEventListener("mousemove", () => {
      resetTimer();
      handleLogoutTimer();
    });
    document.addEventListener("keydown", () => {
      resetTimer();
      handleLogoutTimer();
    });
  }, []);
  useEffect(() => {
    if (countdown > 0) {
      secondsToTime(countdown);
    }
    if (countdown === 0) {
      clearsession();
    }
  }, [countdown]);
  return (
    <>
      {" "}
      {inactive && (
        <>
          <div className="alertoverlay"></div>
          <div className="alertpopup">
            <div className="alert-container">
              <div className="alertside">
                <div className="alerticon">
                  <img src={Inactive} />
                </div>
                <div className="alertext">
                  This browser window will be closed after 2 minutes of
                  inactivity - {timer.toString()} remaining
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InactiveAlert;
