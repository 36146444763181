import { useField } from "formik";
import React from "react";
import "../../style/components/Input.css";
import { ReactComponent as Password } from "../../assets/images/view.svg";
import { ReactComponent as PasswordHidden } from "../../assets/images/hidden.svg";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  time?: boolean;
  label?: string;
  addClass?: string;
  type?: string;
  id?: string;
  icon?: "search" | "password" | "delete" | "file" | "toggle" | null;
  onIconClick?: (e: any) => void;
  disabled?: boolean;
}

const TextInput: React.FC<Props> = ({
  label = "",
  addClass = "",
  id,
  type = "",
  icon = "",
  onIconClick,
  disabled,
  ...props
}) => {
  const [field, meta] = useField({ name: props.name });
  const handleClick = (e: any) => {
    if (e.keyCode === 13) {
      e.target.click();
    }
  };
  return (
    <div className={`group-input ${addClass ? addClass : ""}`}>
      <div className="relative">
        <input
          className="field-text"
          id={id}
          {...field}
          {...props}
          type={type}
          disabled={disabled}
        />
        {label ? (
          <label htmlFor={id} className="input-label">
            {label}
          </label>
        ) : (
          <label htmlFor={id}>
            <span className="sr-only">dummy</span>
          </label>
        )}
        {icon === "password" && (
          <div tabIndex={0} onKeyDown={handleClick} className="password-input onkeyup"  onClick={onIconClick}>
            {type === "password" ? <PasswordHidden /> : <Password />}
          </div>
        )}
        {icon === "confirmpassword" && (
          <div tabIndex={0} onKeyDown={handleClick} className="password-input onkeyup" onClick={onIconClick}>
            {type === "password" ? <PasswordHidden /> : <Password />}
          </div>
        )}

        {meta.touched && meta.error ? (
          <div className="aw-error">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};

export default TextInput;
